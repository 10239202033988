var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-content" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              {
                staticClass:
                  "page-title-box d-flex align-items-center justify-content-between"
              },
              [
                _c("div", { staticClass: "card w-100" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v("Ключи " + _vm._s(_vm.resolveTitle()) + " ")
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v("1733683 - всего ключевых запросов в базе")
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex mt-0 justify-content-end" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "col-sm-12 col-md-6 justify-content-end row"
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "dataTables_filter d-flex justify-content-end",
                                  attrs: { id: "datatable-buttons_filter" }
                                },
                                [
                                  _c(
                                    "form",
                                    { staticClass: "app-search d-lg-block" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "position-relative" },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: _vm.searchQuery,
                                                expression: "searchQuery"
                                              }
                                            ],
                                            staticClass: "form-control",
                                            attrs: {
                                              type: "text",
                                              placeholder: "Найти..."
                                            },
                                            domProps: {
                                              value: _vm.searchQuery
                                            },
                                            on: {
                                              input: function($event) {
                                                if ($event.target.composing) {
                                                  return
                                                }
                                                _vm.searchQuery =
                                                  $event.target.value
                                              }
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c("span", {
                                            staticClass: "bx bx-search-alt",
                                            on: { click: _vm.searchKeywords }
                                          })
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c("ag-grid-vue", {
                        staticClass: "ag-theme-alpine",
                        staticStyle: { width: "100%", height: "700px" },
                        attrs: {
                          columnDefs: _vm.columnDefs,
                          rowData: _vm.rowData,
                          localeText: _vm.localization,
                          pagination: true
                        },
                        on: { "grid-ready": _vm.onReady }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }