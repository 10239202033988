const DEFAULT_DOMAINS_TABLE = [
  {
    field: 'id', 
    headerName: '', 
    checkboxSelection: true,
    cellRenderer: "checkboxCellRenderer",
    checkboxSelection: true,
    maxWidth: 100
  },
  {
    field: 'domain_name', 
    headerName: 'Домен',
    resizable: true,
    cellRenderer: "domainNameCellRenderer",
    filter: 'agTextColumnFilter',
    maxWidth: 160
  },
  {
    field: 'links', 
    headerName: '',
    resizable: true, 
    cellRenderer: "linksCellRenderer",
    cellStyle: { "overflow": 'visible' },
    maxWidth: 100
  },
  {
    field: 'status', 
    headerName: 'Статус',
    resizable: true,
    cellRenderer: "statusBarCellRenderer",
    maxWidth: 140
  },
  {
    field: 'expire_date', 
    headerName: 'Дата',
    resizable: true,
    filter: 'agDateColumnFilter',
    cellRenderer: "expireDateCellRenderer",
    maxWidth: 140
  },
  {
    field: 'rate', 
    headerName: 'Ставка',
    resizable: true,
    cellRenderer: "rateCellRenderer",
    maxWidth: 140
  },
  // {
  //   field: 'region', 
  //   headerName: 'Регион',
  //   resizable: true,
  //   filter: 'agTextColumnFilter',
  //   cellRenderer: "regionsCellRenderer",
  //   maxWidth: 100
  // },
  {
    field: 'iks', 
    headerName: 'ИКС',
    resizable: true,
    filter: 'agNumberColumnFilter',
    maxWidth: 100
  },
  {
    field: 'quantity_yandex_moscow', 
    headerName: 'МСК(Я)',
    resizable: true,
    filter: 'agNumberColumnFilter',
    cellRenderer: "yandexMskCellRenderer",
    maxWidth: 120
  },
  {
    field: 'quantity_yandex_spb', 
    headerName: 'СПБ(Я)',
    resizable: true,
    filter: 'agNumberColumnFilter',
    cellRenderer: "yandexSpbCellRenderer",
    maxWidth: 120
  },
  {
    field: 'quantity_yandex_russia', 
    headerName: 'RUS(Я)',
    resizable: true,
    filter: 'agNumberColumnFilter',
    cellRenderer: "yandexRusCellRenderer",
    maxWidth: 120
  },
  {
    field: 'quantity_google_moscow', 
    headerName: 'МСК(G)',
    resizable: true,
    filter: 'agNumberColumnFilter',
    cellRenderer: "googleMskCellRenderer",
    maxWidth: 120
  },
  {
    field: 'created', 
    headerName: 'Создан',
    resizable: true,
    filter: 'agDateColumnFilter',
    cellRenderer: "createdDateCellRenderer",
    maxWidth: 100
  },
  {
    field: 'is_refreshable', 
    headerName: '',
    resizable: true, 
    cellRenderer: "btnCellRenderer",
    maxWidth: 100
  },
];

export default DEFAULT_DOMAINS_TABLE
