import Vue from "vue";

export default Vue.extend({
  template: `<span>{{ createdDate }}</span>`,
  data() {
    return {
      createdDate: null
    }
  },
  mounted() {
    let date = new Date(this.params.data.created)
    this.createdDate = date.getUTCDate() + '.' + (date.getUTCMonth() + 1) + '.' + date.getFullYear()
  }
})
