const GRANTED_DOMAINS_TABLE = [
  {
    field: 'id', 
    headerName: '', 
    checkboxSelection: true,
    cellRenderer: "checkboxCellRenderer",
    checkboxSelection: true
  },
  {
    field: 'domain_name', 
    headerName: 'Домен',
    resizable: true,
    cellRenderer: "domainNameCellRenderer",
    filter: 'agTextColumnFilter',
    minWidth: 140
  },
  {
    field: 'links', 
    headerName: '',
    resizable: true, 
    cellRenderer: "linksCellRenderer",
    cellStyle: { "overflow": 'visible' },
  },
  {
    field: 'status', 
    headerName: 'Статус',
    resizable: true,
    cellRenderer: "statusBarCellRenderer",
  },
  {
    field: 'expire_date', 
    headerName: 'Дата',
    resizable: true,
    filter: 'agDateColumnFilter',
    cellRenderer: "expireDateCellRenderer"
  },
  {
    field: 'rate', 
    headerName: 'Ставка',
    resizable: true,
    cellRenderer: "rateCellRenderer",
  },
  // {
  //   field: 'region', 
  //   headerName: 'Регион',
  //   resizable: true,
  //   filter: 'agTextColumnFilter',
  //   cellRenderer: "regionsCellRenderer"
  // },
  {
    field: 'iks', 
    headerName: 'ИКС',
    resizable: true,
    filter: 'agNumberColumnFilter'
  },
  {
    field: 'quantity_yandex_moscow', 
    headerName: 'МСК(Я)',
    resizable: true,
    filter: 'agNumberColumnFilter',
    cellRenderer: "yandexMskCellRenderer"
  },
  {
    field: 'quantity_yandex_spb', 
    headerName: 'СПБ(Я)',
    resizable: true,
    filter: 'agNumberColumnFilter',
    cellRenderer: "yandexSpbCellRenderer"
  },
  {
    field: 'quantity_yandex_russia', 
    headerName: 'RUS(Я)',
    resizable: true,
    filter: 'agNumberColumnFilter',
    cellRenderer: "yandexRusCellRenderer"
  },
  {
    field: 'quantity_google_moscow', 
    headerName: 'МСК(G)',
    resizable: true,
    filter: 'agNumberColumnFilter',
    cellRenderer: "googleMskCellRenderer"
  },
  {
    field: 'created', 
    headerName: 'Создан',
    resizable: true,
    filter: 'agDateColumnFilter',
    cellRenderer: "createdDateCellRenderer"
  },
  {
    field: 'accessed', 
    headerName: 'Доступен',
    resizable: true,
    cellRenderer: "accessedCellRenderer"
  },
  {
    field: 'is_refreshable', 
    headerName: '',
    resizable: true, 
    cellRenderer: "btnCellRenderer"
  },
];

export default GRANTED_DOMAINS_TABLE
