<template>
  <div class="account-pages my-5 pt-sm-5">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5">
                <div class="card overflow-hidden">
                    <div class="bg-soft-primary">
                        <div class="row">
                            <div class="col-7">
                                <div class="text-primary p-4">
                                    <h5 class="text-primary">Welcome Back !</h5>
                                    <p>Sign in to continue to Atomstal.</p>
                                </div>
                            </div>
                            <div class="col-5 align-self-end">
                                <img v-bind:src="background" alt="" class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <div class="card-body pt-0"> 
                        <div>
                            <a href="/">
                                <div class="avatar-md profile-user-wid mb-4">
                                    <span class="avatar-title rounded-circle bg-light">
                                        <img v-bind:src="logo" alt="" class="rounded-circle" height="34">
                                    </span>
                                </div>
                            </a>
                        </div>
                        <div class="p-2">
                            <form class="form-horizontal" action="/">

                                <div class="form-group">
                                    <label for="username">Username</label>
                                    <input type="text" class="form-control" id="username" placeholder="Enter username" @keyup.enter="login" v-model.trim="username" :class="{ 'form-group--error': $v.username.$error }">
                                    <div v-if="$v.username.$dirty">
                                      <div class="error" v-if="!$v.username.required"><small>Поле должно быть заполнено</small></div>
                                    </div>
                                </div>
        
                                <div class="form-group">
                                    <label for="password">Password</label>
                                    <input type="password" class="form-control" id="password" placeholder="Enter password" @keyup.enter="login" v-model.trim="password" :class="{ 'form-group--error': $v.password.$error }">
                                    <div v-if="$v.password.$dirty">
                                      <div class="error" v-if="!$v.password.required"><small>Поле должно быть заполнено</small></div>
                                    </div>
                                </div>

                                <div class="form-group server-error" v-if="isServerSideError">
                                  <div class="error"><small>Неправильное имя пользователя или пароль</small></div>
                                </div>
        
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="customControlInline">
                                    <label class="custom-control-label" for="customControlInline">Remember me</label>
                                </div>
                                
                                <div class="mt-3">
                                    <button class="btn btn-primary btn-block waves-effect waves-light" @click.prevent="login">Log In</button>
                                </div>
    
                                <!-- <div class="mt-4 text-center">
                                    <a href="auth-recoverpw.html" class="text-muted"><i class="mdi mdi-lock mr-1"></i> Forgot your password?</a>
                                </div> -->
                            </form>
                        </div>
    
                    </div>
                </div>
                <div class="mt-5 text-center">
                    
                    <div>
                        <p>© 2021. Crafted with <i class="mdi mdi-heart text-danger"></i> by Max & Anton</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
  </div>  
</template>

<script>

import {required} from 'vuelidate/lib/validators';
import 'vue-loading-overlay/dist/vue-loading.css';
import $ from 'jquery';

export default {
  name: "LoginPage",
  props: [
    'logo', 'background'
  ],
  data() {
    return {
      username: null,
      password: null,
      isServerSideError: false
    }
  },
  validations: {
    username: {required},
    password: {required}
  },
  methods: {
    login() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.axios.post('/api/login', {
            username: this.username, 
            password: this.password, 
            _csrf_token: $('input[name="token"]').val()
        }).then(response => {
          window.location = "/admin/domains"
        }).catch(error => {
          if (error) {
            this.isServerSideError = true
            $('input.form-control').addClass('server-form-error')
            $('.server-error').removeClass('d-none');

            $('input.form-control').keyup(function() {
              $('input.form-control').removeClass('server-form-error')
              $('.server-error').addClass('d-none');
              this.isServerSideError = false
            })
          }
        })
      }

    }
  }
}
</script>

<style scoped>
  .bg-light {
    padding: 17px 8px !important;
  }
  .form-group--error, .server-form-error {
    border-color: #e66262 !important;
  }
  .error {
    color: #e66262 !important;
  }
</style>
