<template>
  <div id="layout-wrapper">
    <header-block
      :light_logo_svg="light_logo_svg"
      :light_logo_png="light_logo_png"
      :avatar="avatar"
      :username="username"
    ></header-block>
    <sidebar-block
      :is_superadmin="is_superadmin"
    ></sidebar-block>
    <edit-domain-form
      :username="username"
    ></edit-domain-form>
    <footer-block></footer-block>
  </div>
</template>

<script>

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import EditDomainForm from "./EditDomainForm";

export default {
  name: "EditDomainPage",
  props: [
    'light_logo_svg', 'light_logo_png', 'avatar', 'is_superadmin', 'username'
  ],
  components: {
    "header-block": Header,
    "sidebar-block": Sidebar,
    "footer-block": Footer,
    "edit-domain-form": EditDomainForm
  }
}
</script>

<style scoped>

</style>
