var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main-content" }, [
      _c("div", { staticClass: "page-content" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                {
                  staticClass:
                    "page-title-box d-flex align-items-center justify-content-between"
                },
                [
                  _c("div", { staticClass: "card w-100" }, [
                    _c("div", { staticClass: "card-body" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v("aleads.ru ")
                      ]),
                      _vm._v(" "),
                      _c("span", { staticClass: "badge badge-warning" }, [
                        _vm._v("Освобождается")
                      ]),
                      _vm._v(" - 13.09.2021\n                "),
                      _vm._v(" "),
                      _c(
                        "ul",
                        {
                          staticClass: "nav nav-tabs mt-4",
                          attrs: { role: "tablist" }
                        },
                        [
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link active",
                                attrs: {
                                  "data-toggle": "tab",
                                  href: "#home",
                                  role: "tab"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "d-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-home" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-block" },
                                  [_vm._v("Информация")]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  "data-toggle": "tab",
                                  href: "#profile",
                                  role: "tab"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "d-block d-sm-none" },
                                  [_c("i", { staticClass: "far fa-user" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-block" },
                                  [_vm._v("Яндекс МСК (317)")]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  "data-toggle": "tab",
                                  href: "#messages",
                                  role: "tab"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "d-block d-sm-none" },
                                  [_c("i", { staticClass: "far fa-envelope" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-block" },
                                  [_vm._v("Яндекс СПБ - ")]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  "data-toggle": "tab",
                                  href: "#settings",
                                  role: "tab"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "d-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-cog" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-block" },
                                  [_vm._v("Яндекс Россия (3213)")]
                                )
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("li", { staticClass: "nav-item" }, [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  "data-toggle": "tab",
                                  href: "#settings",
                                  role: "tab"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "d-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-cog" })]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-block" },
                                  [_vm._v("Google Россия (332)")]
                                )
                              ]
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "tab-content p-3 text-muted" }, [
                        _c(
                          "div",
                          {
                            staticClass: "tab-pane active",
                            attrs: { id: "home", role: "tabpanel" }
                          },
                          [
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                "\n                      Raw denim you probably haven't heard of them jean shorts Austin.\n                      Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache\n                      cliche tempor, williamsburg carles vegan helvetica. Reprehenderit\n                      butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi,\n                      qui irure terry richardson ex squid. Aliquip placeat salvia cillum\n                      iphone. Seitan aliquip quis cardigan american apparel, butcher\n                      voluptate nisi qui.\n                    "
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tab-pane",
                            attrs: { id: "profile", role: "tabpanel" }
                          },
                          [
                            _c(
                              "table",
                              {
                                staticClass:
                                  "table table-striped table-bordered dt-responsive nowrap",
                                staticStyle: {
                                  "border-collapse": "collapse",
                                  "border-spacing": "0",
                                  width: "100%"
                                },
                                attrs: { id: "datatable-buttons" }
                              },
                              [
                                _c("thead", [
                                  _c("tr", [
                                    _c("th", [_vm._v("Ключ")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Результатов")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Частота")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Частота!")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Позиция")]),
                                    _vm._v(" "),
                                    _c("th", [_vm._v("Доменов")])
                                  ])
                                ]),
                                _vm._v(" "),
                                _c("tbody", [
                                  _c("tr", [
                                    _c("td", [
                                      _vm._v("услуги юриста в пензе цены")
                                    ]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v("18000000")]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v("16")]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v("4")]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v("21")]),
                                    _vm._v(" "),
                                    _c("td", [_vm._v("+7 доменов")])
                                  ])
                                ])
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tab-pane",
                            attrs: { id: "messages", role: "tabpanel" }
                          },
                          [
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                "\n                      Etsy mixtape wayfarers, ethical wes anderson tofu before they\n                      sold out mcsweeney's organic lomo retro fanny pack lo-fi\n                      farm-to-table readymade. Messenger bag gentrify pitchfork\n                      tattooed craft beer, iphone skateboard locavore carles etsy\n                      salvia banksy hoodie helvetica. DIY synth PBR banksy irony.\n                      Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh\n                      mi whatever gluten yr.\n                    "
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "tab-pane",
                            attrs: { id: "settings", role: "tabpanel" }
                          },
                          [
                            _c("p", { staticClass: "mb-0" }, [
                              _vm._v(
                                "\n                      Trust fund seitan letterpress, keytar raw denim keffiyeh etsy\n                      art party before they sold out master cleanse gluten-free squid\n                      scenester freegan cosby sweater. Fanny pack portland seitan DIY,\n                      art party locavore wolf cliche high life echo park Austin. Cred\n                      vinyl keffiyeh DIY salvia PBR, banh mi before they sold out\n                      farm-to-table VHS.\n                    "
                              )
                            ])
                          ]
                        )
                      ])
                    ])
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }