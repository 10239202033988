var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-content" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c(
              "div",
              {
                staticClass:
                  "page-title-box d-flex align-items-center justify-content-between"
              },
              [
                _c("div", { staticClass: "card w-100" }, [
                  _c(
                    "div",
                    { staticClass: "card-body" },
                    [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.title))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex mb-0" }, [
                        _c(
                          "div",
                          {
                            staticClass: "custom-control custom-checkbox mr-3"
                          },
                          [
                            _c("input", {
                              staticClass: "custom-control-input",
                              attrs: { type: "checkbox", id: "selectAll" },
                              on: { click: _vm.checkAllDomains }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "custom-control-label",
                                attrs: { for: "selectAll" }
                              },
                              [_vm._v("Выбрать всё")]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "custom-control custom-checkbox mr-3"
                          },
                          [
                            _c("input", {
                              staticClass: "custom-control-input",
                              attrs: {
                                type: "checkbox",
                                id: "selectAllNullable"
                              },
                              on: { click: _vm.checkNullabelDomains }
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                staticClass: "custom-control-label",
                                attrs: { for: "selectAllNullable" }
                              },
                              [_vm._v("Выбрать все нулевые домены")]
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "actions d-none my-2" }, [
                        _c("i", {
                          staticClass: "bx bx-trash-alt text-danger",
                          attrs: {
                            "data-toggle": "tooltip",
                            "data-placement": "top",
                            title: "",
                            "data-original-title": "Удалить домены"
                          },
                          on: { click: _vm.removeDomains }
                        })
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "row mt-0" }, [
                        _c(
                          "div",
                          { staticClass: "col-sm-12 col-md-6 action-buttons" },
                          [
                            _c("div", { staticClass: "dt-buttons flex-wrap" }, [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary waves-effect waves-light w-sm",
                                  attrs: { type: "button" },
                                  on: { click: _vm.copyToClipboard }
                                },
                                [
                                  _vm._v(
                                    "\n                        Copy\n                      "
                                  )
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "btn btn-primary waves-effect waves-light w-sm",
                                  attrs: { type: "button" },
                                  on: { click: _vm.exportExcel }
                                },
                                [
                                  _vm._v(
                                    "\n                        Excel\n                      "
                                  )
                                ]
                              )
                            ])
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-sm-12 col-md-6" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "dataTables_filter d-flex justify-content-end",
                              attrs: { id: "datatable-buttons_filter" }
                            },
                            [
                              _c(
                                "form",
                                { staticClass: "app-search d-lg-block" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "position-relative" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.searchQuery,
                                            expression: "searchQuery"
                                          }
                                        ],
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          placeholder: "Найти..."
                                        },
                                        domProps: { value: _vm.searchQuery },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.searchQuery =
                                              $event.target.value
                                          }
                                        }
                                      }),
                                      _vm._v(" "),
                                      _c("span", {
                                        staticClass: "bx bx-search-alt",
                                        on: { click: _vm.searchDomains }
                                      })
                                    ]
                                  )
                                ]
                              )
                            ]
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("ag-grid-vue", {
                        staticClass: "ag-theme-alpine",
                        attrs: {
                          columnDefs: _vm.columnDefs,
                          domLayout: _vm.domLayout,
                          localeText: _vm.localization,
                          pagination: true,
                          rowData: _vm.rowData,
                          rowSelection: _vm.rowSelection,
                          frameworkComponents: _vm.frameworkComponents,
                          suppressHorizontalScroll:
                            _vm.suppressHorizontalScroll,
                          suppressRowClickSelection: true
                        },
                        on: {
                          "grid-ready": _vm.onReady,
                          "selection-changed": _vm.onSelectionChanged
                        }
                      })
                    ],
                    1
                  )
                ])
              ]
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }