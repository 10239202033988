import Vue from "vue";

export default Vue.extend({
  template: `
    <span>
      <span v-if="this.statusId == 1">
        <span class="badge badge-success">Свободен</span>
      </span>
      <span v-else-if="this.statusId == 2">
        <span class="badge badge-warning">Освобождается</span>
      </span>
      <span v-else-if="this.statusId == 3">
        <span class="badge badge-soft-danger" data-toggle="tooltip" data-placement="top" title="" v-bind:data-original-title="'удален '+deletedDate">Удален</span>
      </span>
      <span v-else-if="this.statusId == 4">
        <span class="badge badge-soft-info">Неизвестно</span>
      </span>
      <span v-else-if="this.statusId == 5">
        <span class="badge badge-dark">Занят</span>
      </span>
    </span>
  `,
  data() {
    return {
      statusId: null,
      deletedDate: null
    }
  },
  mounted() {
    let deletedDate = this.params.data.deleted_date;
    let expireDate = this.params.data.expire_date;
    let freeDate = this.params.data.free_date;
    let currentDate = new Date();

    let expireDateInstance = new Date(expireDate)
    let freeDateInstance = new Date(freeDate)

    if (deletedDate !== null) {
      this.statusId = 3
      let date = new Date(this.params.date.deleted_date)
      this.deletedDate = date.getFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate()
    } else if (expireDate !== null || freeDate !== null) {
      if (expireDateInstance > currentDate) {
        this.statusId = 5
      } else if(freeDateInstance > currentDate) {
        this.statusId = 2
      } else {
        this.statusId = 1
      }
    } else {
      this.statusId = 4
    }
  }
})
