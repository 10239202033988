<template>
  <div id="layout-wrapper">
    <header-block
      :light_logo_svg="light_logo_svg"
      :light_logo_png="light_logo_png"
      :avatar="avatar"
      :username="username"
    ></header-block>
    <sidebar-block
      :is_superadmin="is_superadmin"
    ></sidebar-block>
    <domains-table
      :title="title"
      :is_superadmin="is_superadmin"
      :username="username"
      :page="page"
    ></domains-table>
    <footer-block></footer-block>
  </div>
</template>

<script>

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import DomainsTable from "./DomainsTable";

export default {
  name: "DomainsPage",
  props: [
    'light_logo_svg', 'light_logo_png', 'avatar', 'username', 'is_superadmin',
    'title', 'page'
  ],
  components: {
    "header-block": Header,
    "sidebar-block": Sidebar,
    "footer-block": Footer,
    "domains-table": DomainsTable
  }
}
</script>

<style scoped>

</style>