<template>
  <div class="vertical-menu nm-active">
    <div data-simplebar="" class="h-100">
      <div id="sidebar-menu">
        <ul class="metismenu list-unstyled" id="side-menu">
          <li class="menu-title">Домены</li>
          <li v-if="is_superadmin"><a href="/private/all-domains" class=" waves-effect"><i class="dripicons-home"></i><span>Все домены</span></a></li>
          <li><a href="/admin/domains" class=" waves-effect"><i class="dripicons-home"></i><span>Мои домены</span></a></li>
          <li><a href="/admin/saved-domains" class=" waves-effect"><i class="bx bx-bar-chart-alt-2"></i><span>Сохраненные домены</span></a></li>
          <li><a href="#" class=" waves-effect"><i class="bx bx-bar-chart-alt-2"></i><span>Мои домены на покупку</span></a></li>
          <li class="menu-title">Ключи</li>
          <li><a href="/admin/keywords/msk-yandex-keys" class=" waves-effect"><i class="dripicons-graph-pie"></i><span>Яндекс МСК</span></a></li>
          <li><a href="/admin/keywords/spb-yandex-keys" class=" waves-effect"><i class="dripicons-graph-pie"></i><span>Яндекс СПБ</span></a></li>
          <li><a href="/admin/keywords/rus-yandex-keys" class=" waves-effect"><i class="dripicons-graph-pie"></i><span>Яндекс Россия</span></a></li>
          <li><a href="/admin/keywords/msk-google-keys" class=" waves-effect"><i class="dripicons-graph-pie"></i><span>Google МСК</span></a></li>
          <li class="menu-title">Полезные сервисы</li>
          <li><a href="#" class=" waves-effect"><i class="bx bx-help-circle"></i><span>Помощь</span></a></li>
          <li><a href="#" class=" waves-effect"><i class="bx bx-help-circle"></i><span>Помощь</span></a></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import $ from 'jquery'
import 'metismenu'
import Waves from "node-waves"

export default {
  name: "Sidebar",
  props: [
    'is_superadmin'
  ],
  methods: {
    collapseSidebar() {
      ! function(t) {
        "use strict";

        t("#side-menu").metisMenu(), t("#vertical-menu-btn").on("click", function(e) {
          e.preventDefault(), t("body").toggleClass("sidebar-enable")
          992 <= t(window).width() ? t("body").toggleClass("vertical-collpsed") : t("body").removeClass("vertical-collpsed")
        }), Waves.init()
      }($);
    }
  },
  mounted() {
    this.collapseSidebar()
  }
}
</script>

<style scoped>

</style>