import XLSX from "xlsx";
import domainsRowsParser from './domains_rows_parser';

export default (params) => {
  let filename = 'domains.xlsx';
  let data = domainsRowsParser(params);
  let ws = XLSX.utils.json_to_sheet(data);
  let wb = XLSX.utils.book_new();

  XLSX.utils.book_append_sheet(wb, ws, "Domains");
  XLSX.writeFile(wb,filename);
}