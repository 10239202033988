import Vue from 'vue';
import $ from 'jquery';
import swal from 'sweetalert';

export default Vue.extend({
  template: `
    <span>
      <span v-if="!isActive">-</span>
      <span v-else>
        {{ this.rateAmount + ' ₽ ' }}
        <span v-if="!isSaved">
          <a href="javascript:void(0);" @click.prevent="saveDomain($event)" class="mr-3 text-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Сохранить">
            <i class="mdi mdi-file-download-outline font-size-18"></i>
          </a>
        </span>
        <span v-else>
          <a href="javascript:void(0);" @click.prevent="unsaveDomain($event)" class="mr-3 text-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Убрать из сохраненных">
            <i class="mdi mdi-refresh font-size-18"></i>
          </a>
        </span>
      </span>
    </span>
  `,
  data() {
    return {
      isActive: null,
      isSaved: null,
      rateAmount: null,
      activeStore: null
    }
  },
  methods: {
    toggleButtons(event) {
      this.params.data.is_saved = this.params.data.is_saved == 1 ? 0 : 1

      let isSavedStatus = this.params.data.is_saved
      this.activeStore = true
      this.$store.commit('mutateRateDomainsButtonsColumnState', isSavedStatus == 1 ? 0 : 1);
      $(event.target).closest('#parent-buttons-group').find('#base-buttons-group').css("display", "none")
      $('[data-toggle="tooltip"], .tooltip').tooltip("hide");
    },
    saveDomain(event) {
      let api = this.params;
      let domainId = api.data.id
      this.isSaved = true
      this.toggleButtons(event)

      this.axios.post('/api/save-domains', {id: domainId, status: 1}).then(response => {
        let result = response.data

        if (result.status == 'success') {
          swal(`Домен ${api.data.domain_name} был добавлен в сохраненные!`, '', 'success')
        } else {
          swal('Не удалось сохранить домен!', 'Возникла внутренняя ошибка', 'error')
        }
      }).catch(error => {
        if (error) {
          swal('Не удалось сохранить домен!', 'Возникла внутренняя ошибка', 'error')
        }
      })
    },
    unsaveDomain(event) {
      // TODO: 7) Implement domain unsaving
      let api = this.params;
      let domainId = api.data.id
      this.isSaved = false
      this.toggleButtons(event)

      this.axios.post('/api/save-domains', {id: domainId, status: 0}).then(response => {
        let result = response.data

        if (result.status == 'success') {
          swal(`Домен ${api.data.domain_name} был убран из сохраненных!`, '', 'success')
        } else {
          swal('Не удалось сохранить домен!', 'Возникла внутренняя ошибка', 'error')
        }
      }).catch(error => {
        if (error) {
          swal('Не удалось сохранить домен!', 'Возникла внутренняя ошибка', 'error')
        }
      })
    }
  },
  mounted() {
    this.isSaved = this.params.data.is_saved

    if (this.params.value == null) {
      this.isActive = false
    } else {
      this.isActive = true
      this.rateAmount = this.params.value
    }
  }
})
