<template>
  <div>
    <header-block
      :light_logo_svg="light_logo_svg"
      :light_logo_png="light_logo_png"
      :avatar="avatar"
      :username="username"
    ></header-block>
    <sidebar-block
      :is_superadmin="is_superadmin"
    ></sidebar-block>
    <detailed-domain-block></detailed-domain-block>
    <footer-block></footer-block>
  </div>
</template>

<script>

import Header from './Header.vue';
import Sidebar from './Sidebar.vue';
import Footer from './Footer.vue';
import DetailedDomain from './DetailedDomain.vue'

export default {
  name: "DetailedDomainPage",
  props: [
    'light_logo_png', 'light_logo_svg', 'avatar', 'is_superadmin', 'username'
  ],
  components: {
    "header-block": Header,
    "sidebar-block": Sidebar,
    "footer-block": Footer,
    "detailed-domain-block": DetailedDomain
  }
}
</script>
