<template>
  <div class="account-pages my-5 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mb-5">
            <h1 class="display-2 font-weight-medium">{{ first_error_number }}<i class="bx bx-buoy bx-spin text-primary display-3"></i>{{ second_error_number }}</h1>
            <h4 class="text-uppercase">{{ title }}</h4>
            <div class="mt-5 text-center">
              <a class="btn btn-primary waves-effect waves-light" v-bind:href="referer">Вернуться</a>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-8 col-xl-6">
          <div>
            <img v-bind:src="background" alt="" class="img-fluid">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Error500Page",
  props: [
    'background', 'referer', 'title', 'first_error_number', 'second_error_number'
  ]
}
</script>

<style scoped>

</style>
