var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "layout-wrapper" } },
    [
      _c("header-block", {
        attrs: {
          light_logo_svg: _vm.light_logo_svg,
          light_logo_png: _vm.light_logo_png,
          avatar: _vm.avatar,
          username: _vm.username
        }
      }),
      _vm._v(" "),
      _c("sidebar-block", { attrs: { is_superadmin: _vm.is_superadmin } }),
      _vm._v(" "),
      _c("domains-table", {
        attrs: {
          title: _vm.title,
          is_superadmin: _vm.is_superadmin,
          username: _vm.username,
          page: _vm.page
        }
      }),
      _vm._v(" "),
      _c("footer-block")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }