import Vue from "vue";

export default Vue.extend({
  template: `
    <div class="dropdown text-center">
      <a href="#" class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="dripicons-box"></i>
      </a>
      <div class="dropdown-menu">
        <a class="dropdown-item" target="__blank"  v-bind:href="'http://'+ domainName">Перейти на сайт</a>
        <a class="dropdown-item" href="#" @click.prevent="copyDomainNameToClipboard">Копировать</a>
        <div class="dropdown-divider"></div>
          <a class="dropdown-item" v-bind:href="'https://expired.ru/lists/?exp=start&amp;d='+ domainName +'&amp;utm_source=mydrop.io&amp;utm_medium=expired&amp;utm_campaign=menu'" rel="noopener noreferrer" target="_blank"><i class="glyphicon expired-icon"></i> Expired.ru</a>
          <a class="dropdown-item" v-bind:href="'http://www.reg.ru/domain/new/checkmany?dname='+ domainName +'&amp;rid=1532142'" rel="noopener noreferrer" target="_blank"><i class="glyphicon regru-icon"></i> Reg.ru</a>
          <a class="dropdown-item" v-bind:href="'https://whois7.com/?q='+ domainName" rel="noopener noreferrer" target="_blank"><i class="glyphicon whois-icon"></i> Whois</a>
          <a class="dropdown-item" v-bind:href="'https://web.archive.org/web/*/'+ domainName" rel="noopener noreferrer" target="_blank"><i class="glyphicon webarchive-icon"></i> Wayback Machine</a>
          <a class="dropdown-item" v-bind:href="'https://yandex.ru/search/?text=site%3A'+ domainName" rel="noopener noreferrer" target="_blank"><i class="glyphicon yandex-icon"></i> Индекс Яндекс</a>
          <a class="dropdown-item" v-bind:href="'https://www.google.ru/search?q=site%3A'+ domainName" rel="noopener noreferrer" target="_blank">
            <i class="glyphicon google-icon"></i> Индекс Google</a>
          <a  class="dropdown-item" v-bind:href="'http://www.bing.com/search?q=site%3A'+ domainName" rel="noopener noreferrer" target="_blank"><i class="glyphicon bing-icon"></i> Индекс Bing</a>
          <a class="dropdown-item" v-bind:href="'https://wordstat.yandex.ru/#!/?words='+ domainName" rel="noopener noreferrer" target="_blank"><i class="glyphicon wordstat-icon"></i> Wordstat</a>
          <a class="dropdown-item" v-bind:href="'https://webmaster.yandex.ru/sqi/?host='+ domainName" rel="noopener noreferrer" target="_blank"><i class="glyphicon sqi-icon"></i> ИКС (Индекс Качества Сайта)</a>
          <a class="dropdown-item" v-bind:href="'https://www.linkpad.ru/?search='+ domainName" rel="noopener noreferrer" target="_blank"><i class="glyphicon linkpad-icon"></i> Linkpad</a>
          <a class="dropdown-item" v-bind:href="'https://ru.megaindex.com/backlinks/'+ domainName" rel="noopener noreferrer" target="_blank"><i class="glyphicon megaindex-icon"></i> Megaindex Ссылки</a>
          <a class="dropdown-item" v-bind:href="'https://ru.megaindex.com/visibility/'+ domainName +'?ser_id=1,2846,174,3019'" rel="noopener noreferrer" target="_blank"><i class="glyphicon megaindex-icon"></i> Megaindex Видимость</a>
          <a class="dropdown-item" v-bind:href="'https://www.semrush.com/ru/info/'+ domainName" rel="noopener noreferrer" target="_blank"><i class="glyphicon semrush-icon"></i> SemRush</a>
          <a class="dropdown-item" v-bind:href="'http://spywords.ru/sword.php?sword='+ domainName" rel="noopener noreferrer" target="_blank"><i class="glyphicon spywords-icon"></i> SpyWords</a>
          <a class="dropdown-item" v-bind:href="'https://ahrefs.com/site-explorer/overview/v2/subdomains/live?target='+ domainName" rel="noopener noreferrer" target="_blank"><i class="glyphicon ahrefs-icon"></i> Ahrefs</a>
        </div>
      </div>
    </div>
  `,
  data() {
    return {
      domainName: null
    }
  },
  methods: {
    copyDomainNameToClipboard() {
      this.$copyText(this.params.data.domain_name).then(e => {
        swal({title: "Скопировано!", timer: 1000})
      }); 
    }
  },
  mounted() {
    this.domainName = this.params.data.domain_name;
  }
})
