var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "my-5 pt-sm-5" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 text-center" }, [
          _c("div", { staticClass: "home-wrapper" }, [
            _c("div", { staticClass: "mb-5" }, [
              _c("img", { attrs: { src: _vm.logo, alt: "logo", height: "24" } })
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", { staticClass: "col-sm-4" }, [
                _c("div", { staticClass: "maintenance-img" }, [
                  _c("img", {
                    staticClass: "img-fluid mx-auto d-block",
                    attrs: { src: _vm.background, alt: "" }
                  })
                ])
              ])
            ]),
            _vm._v(" "),
            _c("h3", { staticClass: "mt-5" }, [
              _vm._v("Страница в стадии разработки")
            ]),
            _vm._v(" "),
            _c("p", [_vm._v("Пожалуйста вернитесь позже.")])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }