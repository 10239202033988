<template>
  <div>
    <header id="page-topbar">
      <div class="navbar-header">
        <div class="d-flex">
          <div class="navbar-brand-box">
            <a href="/" class="logo logo-light">
            <span class="logo-sm">
                <img v-bind:src="light_logo_svg" alt="" height="22">
            </span>
              <span class="logo-lg">
                <img v-bind:src="light_logo_png" alt="" height="19">
            </span>
            </a>
          </div>
          <button type="button" class="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
            <i class="fa fa-fw fa-bars"></i>
          </button>
          <div class="header-item noti-icon waves-effect p-3">
            <button type="button" class="btn btn-outline-primary  waves-effect waves-light" data-toggle="modal" data-target=".bs-example-modal-center">Добавить домены</button>
          </div>
        </div>
        <div class="d-flex">
          <div class="dropdown d-inline-block">
            <button type="button" class="btn header-item waves-effect" id="page-header-user-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img class="rounded-circle header-profile-user" v-bind:src="avatar" alt="Header Avatar">
              <span class="d-none d-xl-inline-block ml-1">{{ username }}</span>
              <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item text-danger" href="/logout"><i class="bx bx-power-off font-size-16 align-middle mr-1 text-danger"></i> Logout</a>
            </div>
          </div>
        </div>
      </div>
    </header>

    <loading 
      :active.sync="isLoading" 
      :can-cancel="true" 
      :on-cancel="onCancel"
      :is-full-page="fullPage"
    ></loading>
    
    <div class="modal fade bs-example-modal-center" tabindex="-1" role="dialog" aria-labelledby="mySmallModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title mt-0">Добавить домены</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Форма для быстрого добавления доменов списком, без парамметров. Максимальное количество доменов - 50</p>
            <p>Домены указываем без https/http, каждый домен с новой строки</p>
            <form>
              <div class="form-group">
                <textarea class="form-control mb-2" rows="10" v-model.trim="domains" :class="{ 'form-group--error': $v.domains.$error }"></textarea>
                <div class="mb-3" v-if="$v.domains.$dirty">
                  <div class="error" v-if="!$v.domains.required"><small>Поле обязательно должно быть заполнено</small></div>
                  <div class="error" v-if="!$v.domains.maxDomainsItems"><small>Максимальное количество доменов 50</small></div>
                  <div class="error" v-if="!$v.domains.mustNotContainHttp"><small>Домен не должен содержать http/https</small></div>
                </div>
              </div>
              <button type="button" class="btn btn-primary waves-effect waves-light" @click.prevent="createDomains">Добавить</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {required} from 'vuelidate/lib/validators';
import Loading from 'vue-loading-overlay';
import swal from 'sweetalert';
import $ from 'jquery';
import 'vue-loading-overlay/dist/vue-loading.css';

// Custom validators
const maxDomainsItems = function(domains) {
  if (domains.split("\n").length <= 50) {
    return true
  }

  return false
}

const mustNotContainHttp = function(domains) {
  if (domains.search('http') == -1) {
    return true
  } 

  return false
}

export default {
  name: "Header",
  props: [
    'light_logo_svg', 'light_logo_png', 'avatar', 'username'
  ],
  components: {
    Loading
  },
  data() {
    return {
      domains: '',
      submitStatus: null,
      isLoading: false,
      fullPage: true
    }
  },
  validations: {
    domains: {
      required,
      maxDomainsItems,
      mustNotContainHttp
    }
  },
  methods: {
    createDomains() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isLoading = true;
        let list = this.domains.split("\n");
        list = list.map(domain => domain.trim())

        this.axios.post('/api/add-domains', {username: this.username, domains: list}).then(response => {
          let swalOptions = {};

          if (response.status == 'error') {
            swalOptions.title = 'Возникла внутренняя ошибка!'
            swalOptions.icon = 'warning'
            swalOptions.dangerMode = true
          } else {
            swalOptions.title = 'Домены добавлены!'
            swalOptions.icon = 'success'
            swalOptions.dangerMode = false
          }

          this.isLoading = false
          this.domains = ''
          swal(swalOptions)
          $('.bs-example-modal-center').modal('hide')
        })
      }
    },
    onCancel() {
      swal('Домены добавлены!', '', 'success');
    }
  }
}
</script>

<style scoped>
  .form-group--error {
    border-color: red !important;
  }
  .error {
    color: red !important;
  }
</style>