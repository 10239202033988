<template>
  <div id="layout-wrapper">
    <header-block
      :light_logo_svg="light_logo_svg"
      :light_logo_png="light_logo_png"
      :avatar="avatar"
      :username="username"
    ></header-block>
    <sidebar-block
      :is_superadmin="is_superadmin"
    ></sidebar-block>
    <keywords-table
      search_engine="yandex"
      geo="spb"
    ></keywords-table>
    <footer-block></footer-block>
  </div>
</template>

<script>

import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import KeywordsTable from "./KeywordsTable";

export default {
  name: "YandexSpbKeywordsPage",
  props: [
    'light_logo_svg', 'light_logo_png', 'avatar', 'is_superadmin', 'username'
  ],
  components: {
    "header-block": Header,
    "sidebar-block": Sidebar,
    "footer-block": Footer,
    "keywords-table": KeywordsTable
  }
}
</script>

<style lang="sass">
    
</style>
