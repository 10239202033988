<template>
  <section class="my-5 pt-sm-5">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="home-wrapper">
            <div class="mb-5">
              <img v-bind:src="logo" alt="logo" height="24">
            </div>

            <div class="row justify-content-center">
              <div class="col-sm-4">
                <div class="maintenance-img">
                  <img v-bind:src="background" alt="" class="img-fluid mx-auto d-block">
                </div>
              </div>
            </div>
            <h3 class="mt-5">Страница в стадии разработки</h3>
            <p>Пожалуйста вернитесь позже.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "UnderMaintancePage",
  props: ['logo', 'background']
}
</script>

<style scoped>

</style>
