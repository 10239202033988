import Vue from "vue";

export default Vue.extend({
  //v-bind:href="'/admin/domains/domain-'+domainId"
  template: `
    <span>
      <span v-if="!isActive"><a href="#" class="not-active">{{ domainName }}</a></span>
      <span v-else><a href="#">{{ domainName }}</a></span>
    </span>
  `,
  data() {
    return {
      isActive: null,
      domainName: null,
      domainId: null
    }
  },
  mounted() {
    this.domainName = this.params.value
    this.domainId = this.params.data.id

    if (this.params.data.status == 2) {
      this.isActive = true;
    } else {
      this.isActive = false
    }
  }
})
