<template>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
              <div class="card w-100">
                <div class="card-body">
                  <h4 class="card-title">Ключи {{ resolveTitle() }} </h4>
                  <p>1733683 - всего ключевых запросов в базе</p>

                  <div class="d-flex mt-0 justify-content-end">
                    <div class="col-sm-12 col-md-6 justify-content-end row">
                      <div id="datatable-buttons_filter" class="dataTables_filter d-flex justify-content-end">
                        <form class="app-search d-lg-block">
                          <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Найти..." v-model="searchQuery">
                            <span class="bx bx-search-alt" @click="searchKeywords"></span>
                          </div>
                        </form> 
                      </div>
                    </div>
                  </div>

                  <ag-grid-vue
                    style="width: 100%; height: 700px;"
                    class="ag-theme-alpine"
                    :columnDefs="columnDefs"
                    :rowData="rowData"
                    :localeText="localization"
                    :pagination="true"
                    @grid-ready="onReady"
                  ></ag-grid-vue>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {AgGridVue} from "ag-grid-vue";
import AG_GRID_LOCALIZATION from "../localization/ag-grid";
import DEFAULT_KEYWORDS_TABLE from "../tables/default_keywords_table";
import keywords from '../fake/keywords';

export default {
  name: "KeywordsTable",
  props: [
    'search_engine', 'geo'
  ],
  components: {
    "ag-grid-vue": AgGridVue
  },
  data() {
    return {
      title: null,
      searchQuery: null,
      gridOptions: null,
      localization: null,
    }
  },
  beforeMount() {
    // Columns initialization
    this.columnDefs = DEFAULT_KEYWORDS_TABLE

    // TODO: 2) Fake data need to make API request
    this.rowData = keywords;
    this.localization = AG_GRID_LOCALIZATION;
  },
  methods: {
    onReady(params) {
      params.api.sizeColumnsToFit();
      this.gridOptions = params.api.gridOptionsWrapper.gridOptions
    },
    resolveTitle() {
      if (this.search_engine == 'yandex' && this.geo == 'msk') {
        this.title = 'яндекс (москва)';
      } else if (this.search_engine == 'yandex' && this.geo == 'spb') {
        this.title = 'яндекс (спб)';
      } else if (this.search_engine == 'yandex' && this.geo == 'rus') {
        this.title = 'яндекс (россия)';
      } else if (this.search_engine == 'google' && this.geo == 'msk') {
        this.title = 'google (москва)';
      }

      return this.title;
    },
    searchKeywords() {
      this.gridOptions.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Поиск ключевых слов...</span>';
      this.gridOptions.api.showLoadingOverlay();

      // TODO: 11) Implement keywords searching using API request
      let gridApi = this.gridOptions.api
      setTimeout(function() {
        gridApi.hideOverlay();        
      }, 1500);
    }
  }
}
</script>

<style lang="scss">
  @import "ag-grid-community/dist/styles/ag-grid.css";
  @import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  @import "../styles/table.css";
</style>