import Vue from "vue";

export default Vue.extend({
  template: `
    <span>
      <span v-if="!isActive">{{ accessedUsername }}</span>
      <span v-else>{{ accessedUsername }} <small>+ {{ numberOfUsers }} user</small></span>
    </span>
  `,
  data() {
    return {
      isActive: null,
      accessedUsername: null,
      numberOfUsers: null
    }
  },
  mounted() {
    if (this.params.data.accessedUsers.length > 0) {
      this.isActive = true;
      this.accessedUsername = this.params.data.user.username;
      this.numberOfUsers = this.params.data.accessedUsers.length;  
    } else {
      this.isActive = false;
      this.accessedUsername = this.params.data.user.username;
    }
  }
})
