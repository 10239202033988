// CSS styles
import './libs/bootstrap/bootstrap.min.css'
import './libs/bootstrap/bootstrap.bundle.min'
import './styles/app.css'
import 'node-waves/dist/waves.min.css'

// Common libs
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios'
import store from './store'
import Waves from 'node-waves'
import VueClipboard from 'vue-clipboard2'
import vSelect from 'vue-select'
import Vuelidate from 'vuelidate'

// Common components
import UnderMaintenancePage from "./components/UnderMaintenancePage";
import ErrorPage from "./components/ErrorPage";
import LoginPage from "./components/LoginPage";
import DomainsPage from "./components/DomainsPage";
import DetailedDomainPage from "./components/DetailedDomainPage";
import EditDomainPage from "./components/EditDomainPage";
import SavedDomainsPage from "./components/SavedDomainsPage";
import YandexMskKeywordsPage from "./components/YandexMskKeywordsPage";
import YandexSpbKeywordsPage from "./components/YandexSpbKeywordsPage";
import YandexRusKeywordsPage from "./components/YandexRusKeywordsPage";
import GoogleMskKeywordsPage from "./components/GoogleMskKeywordsPage";


VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(Vuelidate)
Vue.use(VueAxios, axios)
Waves.init()

Vue.component('under-maintance-page', UnderMaintenancePage);
Vue.component('error-page', ErrorPage);
Vue.component('login-page', LoginPage);
Vue.component('domains-page', DomainsPage);
Vue.component('edit-domain-page', EditDomainPage);
Vue.component('detailed-domain-page', DetailedDomainPage);
Vue.component('saved-domains-page', SavedDomainsPage);
Vue.component('yandex-msk-keywords-page', YandexMskKeywordsPage);
Vue.component('yandex-spb-keywords-page', YandexSpbKeywordsPage);
Vue.component('yandex-rus-keywords-page', YandexRusKeywordsPage);
Vue.component('google-msk-keywords-page', GoogleMskKeywordsPage);
Vue.component('v-select', vSelect)

new Vue({
    el: '#app',
    store
})
