var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main-content" }, [
    _c("div", { staticClass: "page-content" }, [
      _c("div", { staticClass: "container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [
            _c("div", { staticClass: "card" }, [
              _c("div", { staticClass: "card-body" }, [
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v("Редактирование домена")
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row mt-3" }, [
                  _c("div", { staticClass: "col-lg-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "domain_name_field" }
                        },
                        [_vm._v("Название домена")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.domainName,
                            expression: "domainName"
                          }
                        ],
                        staticClass: "form-control",
                        class: {
                          "form-group--error": _vm.$v.domainName.$error
                        },
                        attrs: {
                          type: "text",
                          placeholder: "domain.com",
                          name: "domain_name",
                          id: "domain_name_field"
                        },
                        domProps: { value: _vm.domainName },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.domainName = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.domainName.$dirty
                        ? _c("div", [
                            !_vm.$v.domainName.required
                              ? _c("div", { staticClass: "error" }, [
                                  _c("small", [
                                    _vm._v("Поле должно быть заполнено")
                                  ])
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "expire_date" }
                        },
                        [_vm._v("Дата истечения")]
                      ),
                      _vm._v(" "),
                      !_vm.notActiveExpireDate
                        ? _c("div", [
                            _c("input", {
                              staticClass: "form-control",
                              attrs: { type: "text", readonly: "" },
                              domProps: { value: _vm.expireDate }
                            })
                          ])
                        : _c("div", [
                            _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                placeholder: "Дата неизвестна",
                                readonly: ""
                              }
                            })
                          ])
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "rate" }
                        },
                        [_vm._v("Ставка")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.rate,
                            expression: "rate"
                          }
                        ],
                        staticClass: "form-control",
                        class: { "form-group--error": _vm.$v.rate.$error },
                        attrs: { type: "text", name: "rate", id: "rate" },
                        domProps: { value: _vm.rate },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.rate = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.rate.$dirty
                        ? _c("div", [
                            !_vm.$v.rate.numeric
                              ? _c("div", { staticClass: "error" }, [
                                  _c("small", [
                                    _vm._v("Поле должно быть числовым")
                                  ])
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", { staticClass: "control-label" }, [
                          _vm._v("Регион")
                        ]),
                        _vm._v(" "),
                        _c("v-select", {
                          class: { "form-group--error": _vm.$v.region.$error },
                          attrs: {
                            options: _vm.regionsList,
                            getOptionLabel: function(region) {
                              return region.name
                            },
                            name: "regions"
                          },
                          model: {
                            value: _vm.region,
                            callback: function($$v) {
                              _vm.region = $$v
                            },
                            expression: "region"
                          }
                        }),
                        _vm._v(" "),
                        _vm.$v.region.$dirty
                          ? _c("div", [
                              !_vm.$v.region.required
                                ? _c("div", { staticClass: "error" }, [
                                    _c("small", [
                                      _vm._v("Поле должно быть заполнено")
                                    ])
                                  ])
                                : _vm._e()
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        { staticClass: "control-label", attrs: { for: "iks" } },
                        [_vm._v("ИКС")]
                      ),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.iks,
                            expression: "iks"
                          }
                        ],
                        staticClass: "form-control",
                        class: { "form-group--error": _vm.$v.iks.$error },
                        attrs: { type: "text", name: "iks", id: "iks" },
                        domProps: { value: _vm.iks },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.iks = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.$v.iks.$dirty
                        ? _c("div", [
                            !_vm.$v.iks.numeric
                              ? _c("div", { staticClass: "error" }, [
                                  _c("small", [
                                    _vm._v("Поле должно быть числовым")
                                  ])
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "created_date" }
                        },
                        [_vm._v("Дата создания")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        [
                          _c("date-picker", {
                            staticClass: "w-100",
                            class: {
                              "form-group--error": _vm.$v.createdDate.$error
                            },
                            attrs: { value: _vm.createdDate },
                            model: {
                              value: _vm.createdDate,
                              callback: function($$v) {
                                _vm.createdDate = $$v
                              },
                              expression: "createdDate"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.$v.createdDate.$dirty
                        ? _c("div", [
                            !_vm.$v.createdDate.required
                              ? _c("div", { staticClass: "error" }, [
                                  _c("small", [
                                    _vm._v("Поле должно быть заполнено")
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.$v.createdDate.dateCompatible
                              ? _c("div", { staticClass: "error" }, [
                                  _c("small", [
                                    _vm._v("Поле должно быть в формате даты")
                                  ])
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-lg-6" },
                    [
                      _c("label", { staticClass: "control-label" }, [
                        _vm._v("Комментарий")
                      ]),
                      _vm._v(" "),
                      _c("vue-editor", {
                        attrs: { editorToolbar: _vm.defaultEditorToolbar },
                        model: {
                          value: _vm.comment,
                          callback: function($$v) {
                            _vm.comment = $$v
                          },
                          expression: "comment"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-primary waves-effect waves-light w-sm",
                    attrs: { type: "button" },
                    on: { click: _vm.updateDomain }
                  },
                  [_vm._v("\n                Обновить\n              ")]
                )
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }