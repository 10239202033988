export default (data) => {
  let formated = [];
  
  data.forEach(row => {
    let statusName = null;
    let statusDate = null;
    
    let deletedDate = row.deleted_date
    let expireDate = row.expire_date;
    let freeDate = row.free_date;
    let currentDate = new Date();

    if (deletedDate) {
      let date = new Date(deletedDate);
      statusDate = date.getUTCDate() + '.' + (date.getUTCMonth() + 1) + '.' + date.getFullYear()
      statusName = 'Удален'
    } else if (!expireDate < currentDate && expireDate !== null) {
      let date = new Date(expireDate)
      statusDate = date.getUTCDate() + '.' + (date.getUTCMonth() + 1) + '.' + date.getFullYear()
      statusName = 'Освобождается'
    } else if (!freeDate < currentDate && freeDate !== null) {
      let date = new Date(freeDate);
      statusDate = date.getUTCDate() + '.' + (date.getUTCMonth() + 1) + '.' + date.getFullYear()
      statusName = 'Свободен'
    }

    let owner = row.user.username
    let accessed = null

    if (row.accessedUsers.length > 0) {
      accessed = row.accessedUsers.length
    }

    formated.push({
      "Домен": row.domain_name,
      "Статус": statusName,
      "Дата": statusDate,
      "Ставка": row.rate == null ? '-' : row.rate,
      "Регион": row.regions.name,
      "ИКС": row.iks,
      "МСК(Я)": row.quantity_yandex_moscow,
      "СПБ(Я)": row.quantity_yandex_spb,
      "RUS(Я)": row.quantity_yandex_russia,
      "МСК(G)": row.quantity_google_moscow,
      "Создан": row.created,
      "Доступен": accessed == null ? owner : owner + ' + ' + accessed + ' пользователя'
    });
  })

  return formated;
}