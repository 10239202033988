var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-pages my-5 pt-5" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "text-center mb-5" }, [
            _c("h1", { staticClass: "display-2 font-weight-medium" }, [
              _vm._v(_vm._s(_vm.first_error_number)),
              _c("i", {
                staticClass: "bx bx-buoy bx-spin text-primary display-3"
              }),
              _vm._v(_vm._s(_vm.second_error_number))
            ]),
            _vm._v(" "),
            _c("h4", { staticClass: "text-uppercase" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-5 text-center" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-primary waves-effect waves-light",
                  attrs: { href: _vm.referer }
                },
                [_vm._v("Вернуться")]
              )
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-md-8 col-xl-6" }, [
          _c("div", [
            _c("img", {
              staticClass: "img-fluid",
              attrs: { src: _vm.background, alt: "" }
            })
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }