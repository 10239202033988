import Vue from "vue";
import $ from "jquery";

export default Vue.extend({
  template: `
    <span>
      <span v-if="!activeStore">
        <span v-if="!isActive">
          <a v-bind:href="'/admin/domains/edit-domain-'+ domainId" class="mr-3 text-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Редактировать">
            <i class="mdi mdi-pencil font-size-18"></i>
          </a>
          <a href="javascript:void(0);" @click.prevent="deleteDomain($event)" class="text-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Удалить">
            <i class="mdi mdi-close font-size-18"></i>
          </a>
        </span>
        <span v-else>
          <a href="javascript:void(0);" @click.prevent="refreshDomain($event)" class="mr-3 text-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Восстановить">
            <i class="mdi mdi-refresh font-size-18"></i>
          </a>
        </span>
      </span>
      <span v-else>
        <span v-if="this.params.data.is_refreshable !== 1">
          <a v-bind:href="'/admin/domains/edit-domain-'+ domainId" class="mr-3 text-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Редактировать">
            <i class="mdi mdi-pencil font-size-18"></i>
          </a>
          <a href="javascript:void(0);" @click.prevent="deleteDomain($event)" class="text-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Удалить">
            <i class="mdi mdi-close font-size-18"></i>
          </a>
        </span>
        <span v-else>
          <a href="javascript:void(0);" @click.prevent="refreshDomain($event)" class="mr-3 text-primary" data-toggle="tooltip" data-placement="top" title="" data-original-title="Восстановить">
            <i class="mdi mdi-refresh font-size-18"></i>
          </a>
        </span>
      </span>
    </span>
  `,
  data() {
    return {
      isActive: null,
      domainId: 0,
      activeStore: false
    }
  },
  methods: {
    toggleButtons() {
      this.params.data.is_refreshable = this.params.data.is_refreshable == 1 ? 0 : 1
      this.activeStore = true
      $('[data-toggle="tooltip"], .tooltip').tooltip("hide")
    },
    showSweetAlert(title, text, icon, mode) {
      swal({
        title: title,
        text: text,
        icon: icon,
        dangerMode: mode,
      })
    },
    refreshDomain() {
      let api = this.params
      let domainId = api.data.id
      let status = this.params.data.is_refreshable == 1 ? 0 : 1

      this.axios.post('/api/refresh-domain', {id: domainId, status: status}).then(response => {
        let result = response.data

        if (result.status == 'success') {
          this.toggleButtons()
          this.showSweetAlert('Восстановлен!', `Домен ${this.params.data.domain_name} был восстановлен`, result.status, true)
        } else {
          this.showSweetAlert('Внутренняя ошибка!', 'Не удалось восставновить домен', result.status, false)
        }
      })
    },
    deleteDomain(event) {
      // TODO: 6) Implement domain deleting making API request
      let api = this.params;
      let domainId = api.data.id
      let status = this.params.data.is_refreshable == 1 ? 0 : 1

      swal({
        title: `Вы действительно хотите удалить домен ${this.params.data.domain_name}`,
        buttons: {
          confirm: {
            text: "Да",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          },
        },
        icon: "warning",
        dangerMode: true
      }).then((result) => {
        if (result) {
          this.toggleButtons(event)
          swal('Удален!', '', 'success')
        }
      })
    }
  },
  mounted() {
    if (this.params.value !== 1) {
      this.isActive = false;
      this.domainId = this.params.data.id;
    } else {
      this.isActive = true;
      this.domainId = this.params.data.id;
    }
  }
});
