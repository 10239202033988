var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("header", { attrs: { id: "page-topbar" } }, [
        _c("div", { staticClass: "navbar-header" }, [
          _c("div", { staticClass: "d-flex" }, [
            _c("div", { staticClass: "navbar-brand-box" }, [
              _c(
                "a",
                { staticClass: "logo logo-light", attrs: { href: "/" } },
                [
                  _c("span", { staticClass: "logo-sm" }, [
                    _c("img", {
                      attrs: { src: _vm.light_logo_svg, alt: "", height: "22" }
                    })
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "logo-lg" }, [
                    _c("img", {
                      attrs: { src: _vm.light_logo_png, alt: "", height: "19" }
                    })
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _vm._m(1)
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex" }, [
            _c("div", { staticClass: "dropdown d-inline-block" }, [
              _c(
                "button",
                {
                  staticClass: "btn header-item waves-effect",
                  attrs: {
                    type: "button",
                    id: "page-header-user-dropdown",
                    "data-toggle": "dropdown",
                    "aria-haspopup": "true",
                    "aria-expanded": "false"
                  }
                },
                [
                  _c("img", {
                    staticClass: "rounded-circle header-profile-user",
                    attrs: { src: _vm.avatar, alt: "Header Avatar" }
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "d-none d-xl-inline-block ml-1" }, [
                    _vm._v(_vm._s(_vm.username))
                  ]),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "mdi mdi-chevron-down d-none d-xl-inline-block"
                  })
                ]
              ),
              _vm._v(" "),
              _vm._m(2)
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("loading", {
        attrs: {
          active: _vm.isLoading,
          "can-cancel": true,
          "on-cancel": _vm.onCancel,
          "is-full-page": _vm.fullPage
        },
        on: {
          "update:active": function($event) {
            _vm.isLoading = $event
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade bs-example-modal-center",
          attrs: {
            tabindex: "-1",
            role: "dialog",
            "aria-labelledby": "mySmallModalLabel",
            "aria-hidden": "true"
          }
        },
        [
          _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(3),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("p", [
                  _vm._v(
                    "Форма для быстрого добавления доменов списком, без парамметров. Максимальное количество доменов - 50"
                  )
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Домены указываем без https/http, каждый домен с новой строки"
                  )
                ]),
                _vm._v(" "),
                _c("form", [
                  _c("div", { staticClass: "form-group" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.domains,
                          expression: "domains",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control mb-2",
                      class: { "form-group--error": _vm.$v.domains.$error },
                      attrs: { rows: "10" },
                      domProps: { value: _vm.domains },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.domains = $event.target.value.trim()
                        },
                        blur: function($event) {
                          return _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.$v.domains.$dirty
                      ? _c("div", { staticClass: "mb-3" }, [
                          !_vm.$v.domains.required
                            ? _c("div", { staticClass: "error" }, [
                                _c("small", [
                                  _vm._v(
                                    "Поле обязательно должно быть заполнено"
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.domains.maxDomainsItems
                            ? _c("div", { staticClass: "error" }, [
                                _c("small", [
                                  _vm._v("Максимальное количество доменов 50")
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.$v.domains.mustNotContainHttp
                            ? _c("div", { staticClass: "error" }, [
                                _c("small", [
                                  _vm._v("Домен не должен содержать http/https")
                                ])
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary waves-effect waves-light",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.createDomains.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Добавить")]
                  )
                ])
              ])
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-sm px-3 font-size-16 header-item waves-effect",
        attrs: { type: "button", id: "vertical-menu-btn" }
      },
      [_c("i", { staticClass: "fa fa-fw fa-bars" })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "header-item noti-icon waves-effect p-3" },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-primary  waves-effect waves-light",
            attrs: {
              type: "button",
              "data-toggle": "modal",
              "data-target": ".bs-example-modal-center"
            }
          },
          [_vm._v("Добавить домены")]
        )
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "dropdown-menu dropdown-menu-right" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-item text-danger",
          attrs: { href: "/logout" }
        },
        [
          _c("i", {
            staticClass:
              "bx bx-power-off font-size-16 align-middle mr-1 text-danger"
          }),
          _vm._v(" Logout")
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title mt-0" }, [
        _vm._v("Добавить домены")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close"
          }
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }