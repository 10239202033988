const keywords = [
    {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
      {keyword: 'мировые судьи юао москвы официальный сайт', results: 24000000, frequency_one: 2, frequency_two: 4, domains_quantity: 2},
]

export default keywords;