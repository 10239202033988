<template>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h4 class="card-title">Редактирование домена</h4>

                <div class="row mt-3">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="domain_name_field" class="control-label">Название домена</label>
                      <input class="form-control" type="text" placeholder="domain.com" name="domain_name" id="domain_name_field" v-model="domainName" :class="{ 'form-group--error': $v.domainName.$error }">
                      <div v-if="$v.domainName.$dirty">
                        <div class="error" v-if="!$v.domainName.required"><small>Поле должно быть заполнено</small></div>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                      <label class="control-label">Статус</label>
                      <v-select 
                        name="statuses" 
                        :options="statusesList" 
                        :getOptionLabel="status => status.name"
                      ></v-select>
                    </div> -->
                    <div class="form-group">
                      <label for="expire_date" class="control-label">Дата истечения</label>
                      <div v-if="!notActiveExpireDate">
                        <input class="form-control" type="text" v-bind:value="expireDate" readonly>
                      </div>
                      <div v-else>
                        <input class="form-control" type="text" placeholder="Дата неизвестна" readonly>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="rate" class="control-label">Ставка</label>
                      <input type="text" name="rate" id="rate" class="form-control" v-model="rate" :class="{ 'form-group--error': $v.rate.$error }">
                      <div v-if="$v.rate.$dirty">
                        <div class="error" v-if="!$v.rate.numeric"><small>Поле должно быть числовым</small></div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="control-label">Регион</label>
                      <v-select 
                        :options="regionsList" 
                        :getOptionLabel="region => region.name"
                        :class="{ 'form-group--error': $v.region.$error }"
                        name="regions" 
                        v-model="region"
                      ></v-select>
                      <div v-if="$v.region.$dirty">
                        <div class="error" v-if="!$v.region.required"><small>Поле должно быть заполнено</small></div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="iks" class="control-label">ИКС</label>
                      <input type="text" name="iks" id="iks" class="form-control" v-model="iks" :class="{ 'form-group--error': $v.iks.$error }">
                      <div v-if="$v.iks.$dirty">
                        <div class="error" v-if="!$v.iks.numeric"><small>Поле должно быть числовым</small></div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for="created_date" class="control-label">Дата создания</label>
                      <div>
                        <date-picker v-model="createdDate" :value="createdDate" class="w-100" :class="{ 'form-group--error': $v.createdDate.$error }"></date-picker>
                      </div>
                      <div v-if="$v.createdDate.$dirty">
                        <div class="error" v-if="!$v.createdDate.required"><small>Поле должно быть заполнено</small></div>
                        <div class="error" v-if="!$v.createdDate.dateCompatible"><small>Поле должно быть в формате даты</small></div>
                      </div>
                    </div>
                    <!-- <div class="form-group">
                      <label for="created_date" class="control-label">Доступен</label>
                      <div>
                        <v-select
                          name="users"
                          :options="usersList"
                          :getOptionLabel="user => user.username"
                        ></v-select>
                      </div>

                    </div> -->
                  </div>

                  <div class="col-lg-6">
                    <label class="control-label">Комментарий</label>
                    <vue-editor
                      v-model="comment"
                      :editorToolbar="defaultEditorToolbar"
                    ></vue-editor>
                  </div>
                </div>

                <button @click="updateDomain" type="button" class="btn btn-primary waves-effect waves-light w-sm">
                  Обновить
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {required, numeric} from 'vuelidate/lib/validators';
import {VueEditor} from "vue2-editor";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/ru";
import swal from "sweetalert";
import defaultEditorToolbar from "../utils/default_editor_toolbar";

// Custom validators
const dateCompatible = function(date) {
  return date instanceof Date && !isNaN(date);
}

export default {
  name: "EditDomainForm",
  props: [
    'username'
  ],
  components: {
    "vue-editor": VueEditor,
    "date-picker": DatePicker
  },
  data() {
    return {
      defaultEditorToolbar: null,
      notActiveExpireDate: false,
      currentDomain: null,
      createdDate: null,
      expireDate: null,
      regionsList: [],
      domainName: null,
      comment: null,
      region: null,
      rate: null,
      iks: null
    }
  },
  validations: {
    domainName: {required},
    region: {required},
    iks: {numeric},
    rate: {numeric},
    createdDate: {required, dateCompatible}
  },
  methods: {
    getCurrentDomainId() {
      let url = new URL(window.location)
  	  return url.pathname.split('/').at(-1).split('-').at(-1);
    },
    resolveExpireDate(expireDate, freeDate) {
      let currentDate = new Date();
      let expireDateInstance = new Date(expireDate)
      let freeDateInstance = new Date(freeDate)
      
      if (expireDateInstance <= currentDate && expireDate !== null) {
        let date = new Date(expireDate);
        this.expireDate = date.getFullYear()  + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate()
        return
      } else if (freeDateInstance <= currentDate && freeDate !== null) {
        let date = new Date(freeDate);
        this.expireDate = date.getFullYear()  + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate()
        return
      } else {
        this.notActiveExpireDate = true
      }
    },
    convertNumber(number) {
      if (number < 10) {
        return '0' + number;
      }
      return number;
    },
    getRegionsList() {
      let context = this

      this.axios.post('/api/list-regions').then(response => {
        let regions = [];
        let result = JSON.parse(response.data)
        result.forEach(region => {
          regions.push({name: region.name, value: region.id})
        })

        context.regionsList = regions
      })
    },
    getDomainById() {
      let context = this
      let id = this.getCurrentDomainId();

      this.axios.post('/api/list-single-domain', {id: id, username: this.username}).then(response => {
        let result = JSON.parse(response.data);

        if (result.length === 0) {
          window.location = '/403'
          return;
        }

        result = result.shift();
        this.resolveExpireDate(result.expire_date, result.free_date)
        context.createdDate = new Date(result.created)
        context.domainName = result.domain_name
        context.region = result.regions
        context.comment = result.comment
        context.rate = result.rate
        context.iks = result.iks
      })
    },
    updateDomain() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        let createdDate = new Date(this.createdDate);
        let created = this.convertNumber(
          createdDate.getFullYear()) + '-' + 
          this.convertNumber(createdDate.getMonth()+1) + '-' + 
          this.convertNumber(createdDate.getDate()) + ' ' + 
          this.convertNumber(createdDate.getHours()) + ':' + 
          this.convertNumber(createdDate.getMinutes()) + ':' + 
          this.convertNumber(createdDate.getSeconds()
        );

        let data = {
          id: this.getCurrentDomainId(),
          domain_name: this.domainName,
          rate: this.rate == '' ? null : this.rate,
          iks: this.iks == '' ? 0 : this.iks,
          comment: this.comment ?? '',
          created: created,
          region_id: this.region.id ?? this.region.value
        }

        this.axios.post('/api/edit-domain', data).then(response => {
          let result = response.data

          if (result.status === 'error') {
            swal(`Не удалось обновить домен`, '', result.status)
          } else {
            swal(`Домен ${this.domainName} был успешно обновлен!`, '', result.status)
          }
        }).catch(error => {
          if (error) {
            swal('Возникла внутренняя ошибка!', '', 'error')
          }
        })
      }
    }
  },
  beforeMount() {
    this.getRegionsList();
    this.getDomainById();
    this.defaultEditorToolbar = defaultEditorToolbar
  }
}
</script>

<style scoped>
  @import 'vue2-datepicker/index.css';
  @import 'vue-select/dist/vue-select.css';

  .mx-datepicker.form-group--error, .v-select.form-group--error {
    border: 1px solid #e66262 !important;
    border-radius: 5px;
  }
  .form-group--error, .server-form-error {
    border-color: #e66262 !important;
  }
  .error {
    color: #e66262 !important;
  }
</style>
