var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vertical-menu nm-active" }, [
    _c("div", { staticClass: "h-100", attrs: { "data-simplebar": "" } }, [
      _c("div", { attrs: { id: "sidebar-menu" } }, [
        _c(
          "ul",
          {
            staticClass: "metismenu list-unstyled",
            attrs: { id: "side-menu" }
          },
          [
            _c("li", { staticClass: "menu-title" }, [_vm._v("Домены")]),
            _vm._v(" "),
            _vm.is_superadmin ? _c("li", [_vm._m(0)]) : _vm._e(),
            _vm._v(" "),
            _vm._m(1),
            _vm._v(" "),
            _vm._m(2),
            _vm._v(" "),
            _vm._m(3),
            _vm._v(" "),
            _c("li", { staticClass: "menu-title" }, [_vm._v("Ключи")]),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _vm._m(5),
            _vm._v(" "),
            _vm._m(6),
            _vm._v(" "),
            _vm._m(7),
            _vm._v(" "),
            _c("li", { staticClass: "menu-title" }, [
              _vm._v("Полезные сервисы")
            ]),
            _vm._v(" "),
            _vm._m(8),
            _vm._v(" "),
            _vm._m(9)
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      { staticClass: " waves-effect", attrs: { href: "/private/all-domains" } },
      [
        _c("i", { staticClass: "dripicons-home" }),
        _c("span", [_vm._v("Все домены")])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        { staticClass: " waves-effect", attrs: { href: "/admin/domains" } },
        [
          _c("i", { staticClass: "dripicons-home" }),
          _c("span", [_vm._v("Мои домены")])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          staticClass: " waves-effect",
          attrs: { href: "/admin/saved-domains" }
        },
        [
          _c("i", { staticClass: "bx bx-bar-chart-alt-2" }),
          _c("span", [_vm._v("Сохраненные домены")])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { staticClass: " waves-effect", attrs: { href: "#" } }, [
        _c("i", { staticClass: "bx bx-bar-chart-alt-2" }),
        _c("span", [_vm._v("Мои домены на покупку")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          staticClass: " waves-effect",
          attrs: { href: "/admin/keywords/msk-yandex-keys" }
        },
        [
          _c("i", { staticClass: "dripicons-graph-pie" }),
          _c("span", [_vm._v("Яндекс МСК")])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          staticClass: " waves-effect",
          attrs: { href: "/admin/keywords/spb-yandex-keys" }
        },
        [
          _c("i", { staticClass: "dripicons-graph-pie" }),
          _c("span", [_vm._v("Яндекс СПБ")])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          staticClass: " waves-effect",
          attrs: { href: "/admin/keywords/rus-yandex-keys" }
        },
        [
          _c("i", { staticClass: "dripicons-graph-pie" }),
          _c("span", [_vm._v("Яндекс Россия")])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c(
        "a",
        {
          staticClass: " waves-effect",
          attrs: { href: "/admin/keywords/msk-google-keys" }
        },
        [
          _c("i", { staticClass: "dripicons-graph-pie" }),
          _c("span", [_vm._v("Google МСК")])
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { staticClass: " waves-effect", attrs: { href: "#" } }, [
        _c("i", { staticClass: "bx bx-help-circle" }),
        _c("span", [_vm._v("Помощь")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [
      _c("a", { staticClass: " waves-effect", attrs: { href: "#" } }, [
        _c("i", { staticClass: "bx bx-help-circle" }),
        _c("span", [_vm._v("Помощь")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }