<template>
    <div class="main-content">
      <div class="page-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="page-title-box d-flex align-items-center justify-content-between">
                <div class="card w-100">
                  <div class="card-body">

                    <h4 class="card-title">aleads.ru </h4>
                    <span class="badge badge-warning">Освобождается</span> - 13.09.2021
                    <!-- Nav tabs -->
                    <ul class="nav nav-tabs mt-4" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#home" role="tab">
                          <span class="d-block d-sm-none"><i class="fas fa-home"></i></span>
                          <span class="d-none d-sm-block">Информация</span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#profile" role="tab">
                          <span class="d-block d-sm-none"><i class="far fa-user"></i></span>
                          <span class="d-none d-sm-block">Яндекс МСК (317)</span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#messages" role="tab">
                          <span class="d-block d-sm-none"><i class="far fa-envelope"></i></span>
                          <span class="d-none d-sm-block">Яндекс СПБ - </span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#settings" role="tab">
                          <span class="d-block d-sm-none"><i class="fas fa-cog"></i></span>
                          <span class="d-none d-sm-block">Яндекс Россия (3213)</span>
                        </a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" data-toggle="tab" href="#settings" role="tab">
                          <span class="d-block d-sm-none"><i class="fas fa-cog"></i></span>
                          <span class="d-none d-sm-block">Google Россия (332)</span>
                        </a>
                      </li>
                    </ul>

                    <!-- Tab panes -->
                    <div class="tab-content p-3 text-muted">
                      <div class="tab-pane active" id="home" role="tabpanel">
                        <p class="mb-0">
                          Raw denim you probably haven't heard of them jean shorts Austin.
                          Nesciunt tofu stumptown aliqua, retro synth master cleanse. Mustache
                          cliche tempor, williamsburg carles vegan helvetica. Reprehenderit
                          butcher retro keffiyeh dreamcatcher synth. Cosby sweater eu banh mi,
                          qui irure terry richardson ex squid. Aliquip placeat salvia cillum
                          iphone. Seitan aliquip quis cardigan american apparel, butcher
                          voluptate nisi qui.
                        </p>
                      </div>
                      <div class="tab-pane" id="profile" role="tabpanel">
                        <table id="datatable-buttons" class="table table-striped table-bordered dt-responsive nowrap" style="border-collapse: collapse; border-spacing: 0; width: 100%;">
                          <thead>
                          <tr>
                            <th>Ключ</th>
                            <th>Результатов</th>
                            <th>Частота</th>
                            <th>Частота!</th>
                            <th>Позиция</th>
                            <th>Доменов</th>

                          </tr>
                          </thead>
                          <tbody>
                          <tr>
                            <td>услуги юриста в пензе цены</td>
                            <td>18000000</td>
                            <td>16</td>
                            <td>4</td>
                            <td>21</td>
                            <td>+7 доменов</td>

                          </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="tab-pane" id="messages" role="tabpanel">
                        <p class="mb-0">
                          Etsy mixtape wayfarers, ethical wes anderson tofu before they
                          sold out mcsweeney's organic lomo retro fanny pack lo-fi
                          farm-to-table readymade. Messenger bag gentrify pitchfork
                          tattooed craft beer, iphone skateboard locavore carles etsy
                          salvia banksy hoodie helvetica. DIY synth PBR banksy irony.
                          Leggings gentrify squid 8-bit cred pitchfork. Williamsburg banh
                          mi whatever gluten yr.
                        </p>
                      </div>
                      <div class="tab-pane" id="settings" role="tabpanel">
                        <p class="mb-0">
                          Trust fund seitan letterpress, keytar raw denim keffiyeh etsy
                          art party before they sold out master cleanse gluten-free squid
                          scenester freegan cosby sweater. Fanny pack portland seitan DIY,
                          art party locavore wolf cliche high life echo park Austin. Cred
                          vinyl keffiyeh DIY salvia PBR, banh mi before they sold out
                          farm-to-table VHS.
                        </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "DetailedDomain"
}
</script>

<style scoped>

</style>