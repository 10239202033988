import { render, staticRenderFns } from "./KeywordsTable.vue?vue&type=template&id=ebf8914e&"
import script from "./KeywordsTable.vue?vue&type=script&lang=js&"
export * from "./KeywordsTable.vue?vue&type=script&lang=js&"
import style0 from "./KeywordsTable.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("c:\\OpenServer\\domains\\bukvarix-v2\\src\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ebf8914e')) {
      api.createRecord('ebf8914e', component.options)
    } else {
      api.reload('ebf8914e', component.options)
    }
    module.hot.accept("./KeywordsTable.vue?vue&type=template&id=ebf8914e&", function () {
      api.rerender('ebf8914e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets/components/KeywordsTable.vue"
export default component.exports