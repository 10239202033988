<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6">
          {{ this.getFullYear() }} © atomstal96.ru
        </div>
        <div class="col-sm-6">
          <div class="text-sm-right d-none d-sm-block">
            Design & Develop by Max & Anton
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    getFullYear() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style scoped>

</style>