import Vue from "vue";

export default Vue.extend({
  template: `<span>{{ regionName }}</span>`,
  data() {
    return {
      regionName: null
    }
  },
  mounted() {
    this.regionName = this.params.data.regions.name
  }
})
