import Vue from "vue";

// This component will be removed when will resolve situation with domains keywords
export default Vue.extend({
  template: `<a v-bind:href="'https://www.bukvarix.com/site/?q='+domain+'&region='+region" target="_blank">{{ results }}</a>`,
  data() {
    return {
      domain: null,
      region: null,
      results: null
    }
  },
  beforeMount() {
    this.domain = this.params.data.domain_name;
    this.region = 'spb';
    this.results = this.params.data.quantity_yandex_spb;
  }
})
