<template>
  <div class="main-content">
    <div class="page-content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
              <div class="card w-100">
                <div class="card-body">
                  <h4 class="card-title">{{ title }}</h4>
                  <div class="d-flex mb-0">
                    <div class="custom-control custom-checkbox mr-3">
                      <input type="checkbox" class="custom-control-input" id="selectAll" @click="checkAllDomains">
                      <label class="custom-control-label" for="selectAll">Выбрать всё</label>
                    </div>
                    <div class="custom-control custom-checkbox mr-3">
                      <input type="checkbox" class="custom-control-input" id="selectAllNullable" @click="checkNullabelDomains">
                      <label class="custom-control-label" for="selectAllNullable">Выбрать все нулевые домены</label>
                    </div>
                  </div>

                  <div class="actions d-none my-2">
                    <i class="bx bx-trash-alt text-danger" data-toggle="tooltip" data-placement="top" title="" data-original-title="Удалить домены" @click="removeDomains"></i>
                  </div>

                  <div class="row mt-0">
                    <div class="col-sm-12 col-md-6 action-buttons">
                      <div class="dt-buttons flex-wrap">   
                        <button type="button" class="btn btn-primary waves-effect waves-light w-sm" @click="copyToClipboard">
                          Copy
                        </button>
                        <button type="button" class="btn btn-primary waves-effect waves-light w-sm" @click="exportExcel">
                          Excel
                        </button>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div id="datatable-buttons_filter" class="dataTables_filter d-flex justify-content-end">
                        <form class="app-search d-lg-block">
                          <div class="position-relative">
                            <input type="text" class="form-control" placeholder="Найти..." v-model="searchQuery">
                            <span class="bx bx-search-alt" @click="searchDomains"></span>
                          </div>
                        </form> 
                      </div>
                    </div>
                  </div>
                  
                  <ag-grid-vue
                    class="ag-theme-alpine"
                    :columnDefs="columnDefs"
                    :domLayout="domLayout"
                    :localeText="localization"
                    :pagination="true"
                    :rowData="rowData"
                    :rowSelection="rowSelection"
                    :frameworkComponents="frameworkComponents"
                    :suppressHorizontalScroll="suppressHorizontalScroll"
                    :suppressRowClickSelection="true"
                    @grid-ready="onReady"
                    @selection-changed="onSelectionChanged"
                  >
                  </ag-grid-vue>
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>

// Libraries
import { AgGridVue } from "ag-grid-vue";
import $ from "jquery";
import swal from "sweetalert";

// Utils
import exportXlsx from "../utils/domains_xlsx_file";
import domainsRowsData from "../utils/domains_rows_parser";
import jsonToCsv from "../utils/json_to_csv";
import AG_GRID_LOCALIZATION from "../localization/ag-grid";
import DEFAULT_DOMAINS_TABLE from "../tables/default_domains_table";
import GRANTED_DOMAINS_TABLE from "../tables/granted_domains_table";

// Child components, renderers
import CheckboxCellRenderer from "./renderers/domains/CheckboxCellRenderer";
import BtnCellRenderer from "./renderers/domains/ButtonCellRenderer";
import RateCellRenderer from "./renderers/domains/RateCellRenderer";
import ExpireDateCellRenderer from "./renderers/domains/ExpireDateCellRenderer";
import CreatedDateCellRenderer from "./renderers/domains/CreatedDateCellRenderer";
import LinksCellRenderer from "./renderers/domains/LinksCellRenderer";
import DomainNameCellRenderer from "./renderers/domains/DomainNameCellRenderer";
import StatusBarCellRenderer from "./renderers/domains/StatusBarCellRenderer";
import AccessedCellRenderer from "./renderers/domains/AccessedCellRenderer";
import RegionsCellRenderer from "./renderers/domains/RegionCellRenderer";
import YandexMskCellRenderer from "./renderers/domains/YandexMskCellRenderer";
import YandexSpbCellRenderer from "./renderers/domains/YandexSpbCellRenderer";
import YandexRusCellRenderer from "./renderers/domains/YandexRusCellRenderer";
import GoogleMskCellRenderer from "./renderers/domains/GoogleMskCellRenderer";

export default {
  name: "DomainsTable",
  props: [
    'title', 'is_superadmin', 'username', 'page'
  ],
  components: {
    "ag-grid-vue": AgGridVue
  },
  data() {
    return {
      api: null,
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      rowData: null,
      rowSelection: null,
      frameworkComponents: null,
      localization: null,
      domLayout: null,
      suppressHorizontalScroll: null,
      suppressRowClickSelection: null,
      searchQuery: null,
    }
  },
  methods: {
    // Actions
    getAllDomains() {
      let context = this

      this.axios.post('/api/list-domains', {limit: 100}).then(response => {
        let result = JSON.parse(response.data);

        context.rowData = result.domains
      });
    },
    getMyDomains() {
      let context = this

      this.axios.post('/api/list-domains', {limit: 100, username: this.username}).then(response => {
        let result = JSON.parse(response.data);

        context.rowData = result.domains
      })
    },
    getSavedDomains() {
      let context = this

      this.axios.post('/api/list-saved-domains', {limit: 100, status: 1, username: this.username}).then(response => {
        let result = JSON.parse(response.data);

        context.rowData = result.domains
      });
    },
    getFilteredRows() {
      let rows = []

      this.gridApi.forEachNodeAfterFilter(node => {
        rows.push(node.data)
      });

      return rows
    },
    exportExcel() {
      return exportXlsx(this.getFilteredRows());
    },
    copyToClipboard() {
      let csv = jsonToCsv(domainsRowsData(this.getFilteredRows()));
      
      this.$copyText(csv).then(e => {
        swal({title: "Скопировано!", timer: 1000})
      });
    },
    checkAllDomains(event) {
      if (!$(event.target).attr('checked')) {
        $(event.target).attr('checked', true)
        $('.actions').removeClass('d-none')
      
        this.gridApi.forEachNode((rowNode, index) => {
          rowNode.setSelected(true);
        });
      } else {
        $(event.target).attr('checked', false)
        $('.actions').addClass('d-none')

        this.gridApi.forEachNode((rowNode, index) => {
          rowNode.setSelected(false);
        });
      }
    },
    checkNullabelDomains(event) {
      if (!$(event.target).attr('checked')) {
        $(event.target).attr('checked', true)
        $('.actions').removeClass('d-none')
      
        this.gridOptions.api.forEachNode(function(node) {
          let rowData = node.data;
          if (rowData.quantity_yandex_moscow == 0 && rowData.quantity_yandex_spb == 0 && rowData.quantity_yandex_russia == 0 && rowData.quantity_google_moscow == 0) {
            node.setSelected(true)
          }
        });
      } else {
        $(event.target).attr('checked', false)
        $('.actions').addClass('d-none')

        this.gridOptions.api.forEachNode(function(node) {
          let rowData = node.data;
          if (rowData.quantity_yandex_moscow == 0 && rowData.quantity_yandex_spb == 0 && rowData.quantity_yandex_russia == 0 && rowData.quantity_google_moscow == 0) {
            node.setSelected(false)
          }
        });
      }
    },
    onSelectionChanged() {
      if (this.api.getSelectedNodes().length < 1) {
        $('.actions').addClass('d-none')
      } else {
        $('.actions').removeClass('d-none')
      }
    },
    removeDomains() {
      let ids = [];
      let selectedNodes = this.gridApi.getSelectedNodes()
      let selectedRows = this.api.getSelectedRows();
      selectedNodes.map(node => ids.push(node.data.id))

      swal({
        title: `Вы действительно хотите удалить выбранные домены?`,
        buttons: {
          confirm: {
            text: "Да",
            value: true,
            visible: true,
            className: "",
            closeModal: true
          },
        },
        icon: "warning",
        dangerMode: true
      }).then(result => {
        if (!result) {
          return
        }

        this.gridOptions.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Удаление доменов...</span>';
        this.gridOptions.api.showLoadingOverlay();
        let gridApi = this.gridOptions.api
        let api = this.api

        this.axios.post('/api/delete-domain', {ids: ids}).then(response => {
          let swalOptions = {};

          if (response.status == 'success') {
            swalOptions.title = 'Возникла внутренняя ошибка!'
            swalOptions.icon = 'warning'
            swalOptions.dangerMode = true
          } else {
            swalOptions.title = 'Домены удалены!'
            swalOptions.icon = 'success'
            swalOptions.dangerMode = false
          }

          gridApi.hideOverlay()
          api.applyTransaction({remove: selectedRows})
          swal(swalOptions)
          $('.actions').addClass('d-none')
          $("#selectAll").prop('checked', false);
          $('#selectAllNullable').prop('checked', false)
        })
      })        
    },
    searchDomains() {
      this.gridOptions.overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Поиск доменов...</span>';
      this.gridOptions.api.showLoadingOverlay();

      // TODO: 9) Implement domain searching using API request
      let gridApi = this.gridOptions.api
      setTimeout(function() {
        gridApi.hideOverlay();        
      }, 1500);
    },
    // All methods about ag-grid
    onReady(params) {
      params.api.sizeColumnsToFit()
      this.api = params.api
      this.columnApi = params.columnApi
      this.gridOptions = params.api.gridOptionsWrapper.gridOptions
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi
    }
  },
  beforeMount() {
    // Columns initialization
    if (this.is_superadmin) {
      this.columnDefs = GRANTED_DOMAINS_TABLE
    } else {
      this.columnDefs = DEFAULT_DOMAINS_TABLE
    }


    this.frameworkComponents = {
      btnCellRenderer: BtnCellRenderer,
      rateCellRenderer: RateCellRenderer,
      expireDateCellRenderer: ExpireDateCellRenderer,
      createdDateCellRenderer: CreatedDateCellRenderer,
      linksCellRenderer: LinksCellRenderer,
      domainNameCellRenderer: DomainNameCellRenderer,
      statusBarCellRenderer: StatusBarCellRenderer,
      accessedCellRenderer: AccessedCellRenderer,
      regionsCellRenderer: RegionsCellRenderer,
      checkboxCellRenderer: CheckboxCellRenderer,
      yandexMskCellRenderer: YandexMskCellRenderer,
      yandexSpbCellRenderer: YandexSpbCellRenderer,
      yandexRusCellRenderer: YandexRusCellRenderer,
      googleMskCellRenderer: GoogleMskCellRenderer,
    };
    this.rowSelection = 'multiple'
    this.localization = AG_GRID_LOCALIZATION
    this.domLayout = 'autoHeight'
    this.suppressHorizontalScroll = true
    this.suppressRowClickSelection = true

    if (this.page == 'all') {
      this.getAllDomains()
    } else if (this.page == 'saved') {
      this.getSavedDomains();
    }else {
      this.getMyDomains()
    }

    // Tooltips initialization
    $(document).ready(function() {
      $("body").tooltip({selector: '[data-toggle=tooltip]'});
    });
  }
}
</script>

<style lang="scss">
  @import "ag-grid-community/dist/styles/ag-grid.css";
  @import "ag-grid-community/dist/styles/ag-theme-alpine.css";
  @import "../styles/table.css";
</style>