import Vue from "vue";

export default Vue.extend({
  template: `
    <span>
      <span v-if="!isActive">-</span>
      <span v-else>{{ this.expireDate }}</span>
    </span>
  `,
  data() {
    return {
      isActive: null,
      expireDate: null
    }
  },
  mounted() {
    let deletedDate = this.params.data.deleted_date
    let expireDate = this.params.data.expire_date;
    let freeDate = this.params.data.free_date;
    let currentDate = new Date();
    let freeDateInstance = new Date(freeDate)
    let expireDateInstance = new Date(expireDate)
    
    if (deletedDate) {
      this.isActive = false;
    } else if (expireDateInstance > currentDate) {
      this.isActive = true;
      this.expireDate = expireDateInstance.getUTCDate() + '.' + (expireDateInstance.getUTCMonth() + 1) + '.' + expireDateInstance.getFullYear()
    } else if (freeDateInstance > currentDate) {
      this.isActive = true;
      this.expireDate = freeDateInstance.getUTCDate() + '.' + (freeDateInstance.getUTCMonth() + 1) + '.' + freeDateInstance.getFullYear()
    } else if (expireDateInstance < currentDate || freeDateInstance < currentDate) {
      if (expireDate !== null) {
        this.isActive = true
        this.expireDate = expireDateInstance.getUTCDate() + '.' + (expireDateInstance.getUTCMonth() + 1) + '.' + expireDateInstance.getFullYear()
      } else if (freeDate) {
        this.isActive = true
        this.expireDate = freeDateInstance.getUTCDate() + '.' + (freeDateInstance.getUTCMonth() + 1) + '.' + freeDateInstance.getFullYear()
      } else {
        this.isActive = false;
      }
    } else {
      this.isActive = false;
    }
  } 
})
