var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "container-fluid" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _vm._v(
            "\n        " +
              _vm._s(this.getFullYear()) +
              " © atomstal96.ru\n      "
          )
        ]),
        _vm._v(" "),
        _vm._m(0)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-6" }, [
      _c("div", { staticClass: "text-sm-right d-none d-sm-block" }, [
        _vm._v("\n          Design & Develop by Max & Anton\n        ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }